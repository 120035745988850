import 'bootstrap';

import LazyMap from './components/LazyMap';
import Trix from './components/Trix';
import PhoneInput from '../shared/components/PhoneInput';
import PreactComponents from '../shared/initializers/PreactComponents';
import IntlQuestions from './components/Questions';

window.onload = () => {
  PreactComponents.init({ components: { IntlQuestions } });
  PhoneInput.init();
  LazyMap.init();
  Trix.init();
};
