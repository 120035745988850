// importing and configuring the trix editor
// see: https://github.com/basecamp/trix
import 'trix/dist/trix.css';
import './index.scss';
import Trix from 'trix';

Trix.config.blockAttributes.default.tagName = 'trix-content';
Trix.config.toolbar.getDefaultHTML = () => '';

Trix.config.blockAttributes.heading1.tagName = 'h2';

Trix.config.blockAttributes.heading2 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

Trix.config.blockAttributes.heading3 = {
  tagName: 'h4',
  terminal: true,
  breakOnReturn: true,
  group: false,
};

function init() {
  // disable accepting file uploads in trix editor
  document.addEventListener('trix-file-accept', (event) => {
    event.preventDefault();
  });
}

export default { init };
