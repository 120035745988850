/* eslint-disable jsx-a11y/label-has-for */
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { fieldId, fieldName } from '../../lib/utils';
import ErrorHint from '../ErrorHint';
import HelpText from '../HelpText';

function Fields({
  base,
  fieldPath,
  question,
  destroy,
  getHumanIndex,
  allowRemove,
}) {
  const index = getHumanIndex(question);

  const idId = fieldId(base, [...fieldPath, 'id']);
  const idName = fieldName(base, [...fieldPath, 'id']);

  const textId = fieldId(base, [...fieldPath, 'text']);
  const textName = fieldName(base, [...fieldPath, 'text']);
  const text = question.text || undefined;
  const textInvalid = question.errors.text;
  const textClass = classNames('form-control', {
    'is-invalid': textInvalid,
  });

  const option1Id = fieldId(base, [...fieldPath, 'option1']);
  const option1Name = fieldName(base, [...fieldPath, 'option1']);
  const option1 = question.option1 || undefined;
  const option1Invalid = question.errors.option1;
  const option1Class = classNames('form-control', {
    'is-invalid': option1Invalid,
  });

  const option2Id = fieldId(base, [...fieldPath, 'option2']);
  const option2Name = fieldName(base, [...fieldPath, 'option2']);
  const option2 = question.option2 || undefined;
  const option2Invalid = question.errors.option2;
  const option2Class = classNames('form-control', {
    'is-invalid': option2Invalid,
  });

  const helpTexts = question.help || undefined;
  const textHelpId = helpTexts ? fieldId(textId, ['help']) : undefined;
  const option1HelpId = helpTexts ? fieldId(option1Id, ['help']) : undefined;
  const option2HelpId = helpTexts ? fieldId(option2Id, ['help']) : undefined;

  const removeButton = allowRemove ? (
    <div className="form-group mb-0">
      <button
        type="button"
        className="btn btn-outline-danger btn-sm"
        onClick={() => destroy(question)}
      >
        <FormattedMessage id="events.events.form.remove_question" />
      </button>
    </div>
  ) : null;

  return (
    <fieldset className="card mb-3">
      <div className="card-header">
        <strong>
          <FormattedMessage
            id="events.events.form.closed_question"
            values={{ index }}
          />
        </strong>
      </div>

      <div className="card-body">
        {question.id && (
          <input type="hidden" value={question.id} name={idName} id={idId} />
        )}

        <div className="form-group">
          <label htmlFor={textId}>
            <strong>Text</strong>
          </label>
          {helpTexts && <HelpText id={textHelpId} text={helpTexts.text} />}
          <textarea
            className={textClass}
            id={textId}
            name={textName}
            defaultValue={text}
            aria-describedby={textHelpId}
          />
          {textInvalid && <ErrorHint messages={question.errors.text} />}
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label htmlFor={option1Id}>
              <strong>Option 1</strong>
            </label>
            {helpTexts && (
              <HelpText id={option1HelpId} text={helpTexts.option1} />
            )}
            <input
              className={option1Class}
              type="text"
              id={option1Id}
              name={option1Name}
              defaultValue={option1}
            />
            {option1Invalid && <ErrorHint messages={question.errors.option1} />}
          </div>

          <div className="form-group col">
            <label htmlFor={option2Id}>
              <strong>Option 2</strong>
            </label>
            {helpTexts && (
              <HelpText id={option2HelpId} text={helpTexts.option2} />
            )}
            <input
              className={option2Class}
              type="text"
              id={option2Id}
              name={option2Name}
              defaultValue={option2}
            />
            {option2Invalid && <ErrorHint messages={question.errors.option2} />}
          </div>
        </div>

        {removeButton}
      </div>
    </fieldset>
  );
}

Fields.defaultProps = {
  question: {},
};

export default Fields;
