import { Component } from 'preact';
import uniqueId from 'lodash.uniqueid';
import { FormattedMessage } from 'react-intl';

import withInternationalization from './withInternationalization';
import { questionTypesMap } from '../lib/constants';
import { destroy, visibleIndexOf } from '../lib/utils';

const generateTmpId = () => uniqueId('tmp');

const getQuestionId = (question) =>
  question.id || question.tmpId || generateTmpId();

export class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: props.questions,
    };

    this.addQuestion = this.addQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.getHumanIndex = this.getHumanIndex.bind(this);
  }

  getHumanIndex(question) {
    const { questions } = this.state;
    const { indexOffset } = this.props;

    return visibleIndexOf(questions, question, indexOffset);
  }

  addQuestion() {
    const tmpId = generateTmpId();
    const newQuestion = { errors: {}, tmpId };
    this.setState((previousState) => ({
      questions: previousState.questions.concat(newQuestion),
    }));
  }

  removeQuestion(question) {
    const { questions } = this.state;
    this.setState({
      questions: destroy(questions, question),
    });
  }

  render() {
    const { questions } = this.state;
    const { type, base, fieldPath, allowAddAndRemove, indexOffset } =
      this.props;
    const Question = questionTypesMap[type];

    const questionItems = questions.map((question, index) => (
      <Question
        key={`${type}-question-${getQuestionId(question)}`}
        index={index + indexOffset}
        question={question}
        destroy={this.removeQuestion}
        getHumanIndex={this.getHumanIndex}
        base={base}
        fieldPath={fieldPath}
        allowRemove={allowAddAndRemove}
      />
    ));

    const addQuestionButton = allowAddAndRemove ? (
      <div className="form-group">
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.addQuestion}
          >
            <FormattedMessage id="events.events.form.add_question" />
          </button>
        </div>
      </div>
    ) : null;

    return (
      <div>
        {questionItems}
        {addQuestionButton}
      </div>
    );
  }
}

Questions.defaultProps = {
  questions: [],
  allowAddAndRemove: true,
  indexOffset: 0,
  base: 'event',
};

export default withInternationalization(Questions);
