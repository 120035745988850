import { render } from 'preact';

function renderComponent(components, node) {
  const Component = components[node.dataset.preactComponent];
  if (!Component) {
    throw new Error(`Unknown component: ${node.dataset.preactComponent}`);
  }
  const props = node.dataset.preactProps
    ? JSON.parse(node.dataset.preactProps)
    : {};
  props.children = node.innerHTML;

  render(<Component {...props} />, node);
}

function renderComponents({ components = {} }) {
  const nodes = document.querySelectorAll('[data-preact-component]');

  nodes.forEach((node) => renderComponent(components, node));
}

export default { init: renderComponents };
